import {Button, Stack, Paper, Card, Typography, CardHeader, CardContent} from "@mui/material";
import "./Community.css";
import {makeStyles} from "@mui/styles";
import { Icon, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import {SiDiscord} from 'react-icons/si';
import OpenSea from './images/opensea.svg'

const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButton-root": {
          border: "2px black solid"
        },
        "&.MuiButton-text": {
          color: "grey"
        },
        "&.MuiButton-contained": {
          color: "white",
          backgroundColor: "black"
          
        },
        "&.MuiButton-contained:hover": {
          // color: "#FEF080",
          color: "white",
          backgroundColor: "black"
          
        },
        "&.MuiButton-outlined": {
          color: "brown"
        }
      }
    };
  });
  
  
       

export default () => {
    const classes = useStyles();      
  return (
    <Paper component={Stack} elevation="4" sx={{ padding:10, backgroundColor:"#efb3f8"}}  direction="column" justifyContent="center" alignItems="center" className='community-background' >
       <Typography variant="h3" sx={{ fontFamily:"Fuzzy Bubbles", marginTop:10  }} color="#black">
             Engage with our Community
              </Typography>  
      <Paper component={Stack} sx={{ backgroundColor:"#efb3f8"}}elevation="0" direction="row"  alignItems="center" className="community-background">        
      <IconButton href="https://www.instagram.com/cryptophreshofficial/" sx={{margin:4, zIndex:3, }}   variant="contained" ><InstagramIcon/></IconButton>
      <IconButton href="https://discord.gg/gkp4s9D7" sx={{zIndex:3}}   variant="contained" ><SiDiscord/></IconButton>
      <IconButton href="https://opensea.io/CryptoPhresh" sx={{marginLeft:4, zIndex:3}}   variant="contained" ><Icon><img src={OpenSea} height={24} width={24}/> </Icon></IconButton>
      </Paper>
    </Paper>
  );
};