// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
import {Stack, Paper, Card, Typography, CardHeader, CardContent} from "@mui/material";
import "./AboutTeam.css";
import ducklin1 from './images/ducklin1.png';
import ducklin2 from './images/ducklin2.png';
import TeamCard from './TeamCard';

// Import Swiper styles
// import 'swiper/css';

export default () => {
    
  return (
    // <Swiper
    //   spaceBetween={50}
    //   slidesPerView={2}
    //   onSlideChange={() => console.log('slide change')}
    //   onSwiper={(swiper) => console.log(swiper)}
    // >
    //   <SwiperSlide>
    //     <TeamCard  name="Karuma" title="Founder and Artist" image={ducklin1} description = "Digital Artist and Musician involved in the NFT space since December 2021."/>
    //   </SwiperSlide>
    //   <SwiperSlide>
    //     <TeamCard name="mNuje" title="Co-Founder and CTO" image={ducklin2} description = "A Software Nerd/Father who enjoys the use of technology to accelerate creativity and fun"/>          
    //   </SwiperSlide>
      
      

    // </Swiper>

    <Paper component={Stack} sx={{backgroundColor:"#222"}} direction="column" justifyContent="center" alignItems="center" className='team-background' >
       <Typography  sx={{ fontFamily:"Fuzzy Bubbles", marginTop:10, fontSize: 32 }} color="#DDD">
              Meet The Team
              </Typography>  
      <Paper component={Stack} sx={{backgroundColor:"#222"}} elevation="0" direction="column"  alignItems="center" className="team-background">        
          <TeamCard  name="Karuma" title="Founder and Artist" image={ducklin1} description = "Digital Artist and Musician involved in the NFT space since December 2021."/>
          <TeamCard name="mNuje" title="Co-Founder" image={ducklin2} description = "A Coder who enjoys the use of technology to accelerate creativity and fun"/>          
      </Paper>
    </Paper>
  );
};