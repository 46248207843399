import './App.css';
import Header from './Header';
import GradientBackground from './GradientBackground';
import Paper from "@mui/material/Paper";
import DucklinsMain from './DucklinsMain';
import AboutTeam from './AboutTeam';
import Community from './Community';
import AboutProject from './AboutProject';
import ConnectWallet from  './ConnectWallet'

function App() {
  return (
    <div className="App">      
      
      <Header/>
   {/* //<GradientBackground> */}
      <DucklinsMain></DucklinsMain>
      <AboutProject></AboutProject>
      <ConnectWallet></ConnectWallet>
      <Community></Community>
      <AboutTeam/>
      
    {/* //</GradientBackground>       */}
        
        
                    
    </div>
  );
}
export default App;