import './Header.css';

import {
    AppBar,
    Toolbar,
    Typography,    
    Button,
  } from "@mui/material";
import {makeStyles} from "@mui/styles";

import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
    header: {
        // backgroundColor: "#FBB0C4aa",
        backdropFilter:"blur(5px)",
        paddingRight: "79px",
        backgroundColor:"#00000033",
        color:"#550000",
        fontFamily: "Fuzzy Bubbles",
        // paddingLeft: "118px",
      },
    logo: {
        fontFamily: "Fuzzy Bubbles",
        fontWeight: 700,
        size: "18px",
        color: "#222",
        textAlign: "left",
        float:"left",
      },
      menuButton: {
        fontFamily: "Fuzzy Bubbles",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
     },
     toolbar: {
        display: "flex",
        justifyContent: "space-between",
      },
 }));

 const headersData = [    
    {
      label: "About",
      href: "/about",
    },
    {
        label: "Community",
        href: "/community",
      },
    {
      label: "Team",
      href: "/team",
    },
    {
      label: "Connect Wallet",
      href: "/connect",
    },
  ];

export default function Header() {
  const { header, logo, menuButton, toolbar } = useStyles();
                     
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };
  

  const femmecubatorLogo = (
    <Typography  sx={{fontFamily:"Fuzzy Bubbles"}} variant="h6" className={logo}>
      CryptoPhresh
    </Typography>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button sx={{fontFamily:"Fuzzy Bubbles"}}
          {...{            
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };
                     
  return (
    <header>
      <AppBar color="transparent" sx={{backdropFilter:"blur(5px)"}}>{displayDesktop()} </AppBar>
    </header>
  );
}