import {Stack, Paper, Card, Typography, CardHeader, CardContent} from "@mui/material";
import "./AboutProject.css";


export default () => {
    
  return (

    // <Paper sx={{ padding:5, backgroundColor:"#60ADD2", color:"#DDD"}}component={Stack} direction="column" justifyContent="center" alignItems="center" className='project-background' >
    <Paper sx={{ padding:5, backgroundColor:"#d26fe0", color:"#222"}} component={Stack} direction="column" justifyContent="center" alignItems="center" className='project-background' >
       <Typography variant="h3" sx={{ marginTop:0}} >
              our project 🌍
        </Typography>  
        
        <Typography variant="p" sx={{ marginTop:2 }}>
        Dumpy Ducklins' bring cute and derpy collectibles to the NFT world.  
</Typography>
<Typography variant="p" sx={{ marginTop:2 }}>        
When we signed the Ducklins', they insisted on moving to the Polygon blockchain because they really like the color green and want to keep Earth that way.
</Typography>
<Typography variant="p" sx={{ marginTop:2 }}>   
The main goal of our project is to bring joy and have fun together with the community, innovating what can be done with NFTs. 
</Typography>
<Typography variant="p" sx={{ marginTop:2 }}>   
Have you ever imagined creating virtual selfies with your NFT?  What other crazy things can we dream up?  We are excited to explore this together.
</Typography>
<Typography variant="p" sx={{ marginTop:2 }}>   
Grab you a Ducklin and join our community -- give us your ideas! We're just getting started.

        </Typography>         

      
    </Paper>
  );
};