import {Button, Stack, Paper, Card, Typography, CardHeader, CardContent} from "@mui/material";
import "./ConnectWallet.css";
import {makeStyles} from "@mui/styles";
import { IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import {SiDiscord} from 'react-icons/si';
  
       

export default () => {

  return (
    <Paper component={Stack} elevation="4" sx={{ padding:10, color:"white", backgroundColor:"#222"}}  direction="column" justifyContent="center" alignItems="center" className='community-background' >
       <Typography variant="h3" sx={{ marginTop:5  }} >Connect Wallet</Typography>
       <Typography variant="h3" sx={{ }} >Coming Soon...</Typography>

       <Typography variant="h5" sx={{ fontWeight:400, fontFamily:"Fuzzy Bubbles", marginTop:10  }} >
               Tell us what kinds of crazy things we can do on this site to make this so much fun
              </Typography>  
      
    </Paper>
  );
};