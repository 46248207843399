import './DucklinsMain.css';
import React from "react";
import Paper from "@mui/material/Paper"; 
import ducklin1 from "./images/ducklin1.png";
import ducklin2 from "./images/ducklin2.png";
import ducklin3 from "./images/ducklin3.png";

import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { rotateIn } from 'react-animations';
import { rotateInDownLeft } from 'react-animations';
import { fadeIn } from 'react-animations';
import {Stack, Button, Card, Typography, CardContent} from "@mui/material/";
import {makeStyles} from "@mui/styles";

const styles = {
  bounce: {
    animation: 'x 5s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  fadeIn: {
    animation: 'x 3s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },

  rotateInDownLeft: {
    animation: 'x 5s',
    animationName: Radium.keyframes(rotateInDownLeft, 'rotateInDownLeft')
  },
}

//styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&.MuiButton-root": {
        border: "2px #222 solid"
      },
      "&.MuiButton-text": {
        color: "grey"
      },
      "&.MuiButton-contained": {
        color: "white",
        backgroundColor: "#222"
        
      },
      "&.MuiButton-contained:hover": {
        // color: "#FEF080",
        color: "white",
        backgroundColor: "#222"
        
      },
      "&.MuiButton-outlined": {
        color: "brown"
      }
    }
  };
});

export default function DucklinsMain() {
     const classes = useStyles();
  
    return (    
        <Paper component={Stack} direction="column" justifyContent="center" alignItems="center" className='background'>
          
          {/* <Typography variant="h2"  sx={{ zIndex:2,}} color="text.primary" gutterBottom>
              Welcome
              </Typography>   */}
              
          <Typography variant="h4"  sx={{ fontFamily:"Fuzzy Bubbles", zIndex:2 }} color="#222" gutterBottom>
            Welcome to Dumpy!          
              </Typography>  
              <Button href="https://opensea.io/CryptoPhresh" sx={{fontFamily:"Fuzzy Bubbles", zIndex:3}} className={classes.root}  variant="contained" >Get You a Ducklin'</Button>
            <StyleRoot>
            
                <div className="test" style={styles.bounce}>
                </div>

                
    
                <img className="ducklin fromleft" src={ducklin1} />
                <img className="ducklin hanging-out-left-bottom" src={ducklin2} />
                <img className="ducklin hanging-out-right" src={ducklin3} />        
            </StyleRoot>
        </Paper>    
    );
  }