
import {Card, Typography, CardContent} from "@mui/material/";
import "./TeamCard.css";

export default function TeamCard(props) {
    
  return (
              
          <Card className="ducklin-card card-background">            
            <CardContent>                                                                
              <Typography align="left" sx={{ fontFamily:"Fuzzy Bubbles", fontWeight: 800, fontSize: 18 }} color="text.primary" gutterBottom>
              {props.name}              
              </Typography>            
              <Typography align="left" sx={{ fontFamily:"Fuzzy Bubbles", fontWeight: 800,fontSize:12 }} color="text.secondary" gutterBottom>

              {props.title}
              </Typography>
                <img  width="100%" src={props.image}/>                            
                <Typography sx={{ fontFamily:"Fuzzy Bubbles", fontWeight: 600, fontSize: 12 }} >
              {props.description}
            </Typography>
              </CardContent>
         </Card>
  );
};